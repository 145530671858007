export const BASE_URL = 'https://us-central1-valmex-si-pro.cloudfunctions.net'
export const KEY="cLAv#Pr1%ncIp*al&pP2O2iV@lm3x#\$i"
export const IV ='v3Ct0R+v41#P_p5i'

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDTumD-FG0AFK8f0xGyWlCRyZLQn4uXLTw",
  authDomain: "valmex-si-pro.firebaseapp.com",
  databaseURL: "https://valmex-si-pro-default-rtdb.firebaseio.com",
  projectId: "valmex-si-pro",
  storageBucket: "valmex-si-pro.appspot.com",
  messagingSenderId: "351711845577",
  appId: "1:351711845577:web:c240b3b7a40d1fb235c118",
  measurementId: "G-TZF6Y5WP1Z"
}
export const usuarioFondos = "GvnFjR+FVybnzovWOm+xpQ==";

export const CALCULADORA_73 = 'https://us-central1-valmex-si-pro.cloudfunctions.net/simuladorPension/calculadora73';
export const CALCULADORA_97 = 'https://us-central1-valmex-si-pro.cloudfunctions.net/simuladorPension/calculadora97';